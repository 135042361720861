@mixin fill-goes-up {
  @include line-options(
    after,
    $transition-property: height,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );

  &:after {
    bottom: 0%;
    right: 0;
    left: 0;
    width: 100%;
  }
}
