.back-to_list{
  padding-left: 0px !important;
}
@media (max-width: 768px) {
  .back-to_list {
    text-decoration: underline !important;
 }
}
.vac{
  &__name{
    color:black !important;
  }
  &__btn{
    background-color:#0084ff;
  }
  &__list-group{
    border: none;
    &:hover{
      .vac__list-elem{
        background-size: 100% .1em;
      }
    }
  }
  &__list-elem{
    background: linear-gradient(currentColor, currentColor) bottom / 0 .1em no-repeat;
    transition: 0.5s background-size;
    background-position: left bottom;
    background-size: 0% .1em;
  
  }
  &___list-elem-employer{
    color:gray;

  }
  &__list-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 
  }
  &__list-elem-salary{
    p{
      margin: 0;
    }
  }
}
.cu-p{
  cursor: pointer;
}
@media (max-width:1254px) {
 .vac__list-block{ 
  flex-direction: column;
 }
}