@mixin right-goes-down {
  @include line-options(
    after,
    $transition-property: height,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );
  &:after {
    top: 0%;
    right: 0%;
  }
}
