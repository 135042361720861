body {
  background-color: white;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Arial", sans-serif;
  color:black;
}
.logo {
  max-height: 80px;
  width: auto;
  margin: 10px;
}
/* Numbered lists like 1, 1.1, 2.2.1... */
ol li {display:block;} /* hide original list counter */
ol > li:first-child {counter-reset: item;} /* reset counter */
ol > li {counter-increment: item; position: relative;} /* increment counter */
ol > li:before {content:counters(item, ".") ". "; position: absolute; margin-right: 100%; right: 10px;} /* print counter */