@mixin both-go-up {
  @include line-options(
    after,
    $transition-property: height,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );

  @include line-options(
    before,
    $transition-property: height,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );

  &:after {
    bottom: 0%;
    left: 0%;
  }

  &:before {
    bottom: 0%;
    right: 0%;
  }
}
