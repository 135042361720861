@mixin move-up {
  @include line-options(
    after,
    $transition-property: bottom,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );
  &:after {
    background-color: transparent;
    bottom: 50%;
    left: 0;
    right: 0;
    width: auto;
  }
}
