@mixin dl-goes-left($top: 45%, $bottom: 45%) {
  @include line-options(
    after,
    $transition-property: width,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );

  @include line-options(
    before,
    $transition-property: width,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );

  &:after {
    right: 0%;
    top: $top;
    z-index: 9;
  }

  &:before {
    right: 0%;
    bottom: $bottom;
    z-index: 9;
    top: 54%;
  }
}
