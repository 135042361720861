$transitioned-properties: width, height;
@mixin fc-top-right {
  @include line-options(
    after,
    $transition-property: $transitioned-properties,
    $value-of-transitioned-property: $max-value-of-transitioned-property,
    $untransitioned-width: 0,
    $untransitioned-height: 0
  );
  &:after {
    top: 0%;
    right: 0;
  }
}
