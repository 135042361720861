@mixin linethrough-opening {
  @include line-options(
    after,
    $transition-property: width,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );

  &:after {
    left: 0;
    top: 50%;
    z-index: 9;
    right: 0;
    margin: 0 auto;
  }
}
