@mixin halfline-bottom-goes-left {
  @include line-options(
    after,
    $transition-property: none,
    $value-of-transitioned-property: $max-value-of-transitioned-property,
    $untransitioned-width: 100%,
    $untransitioned-height: $size-of-the-line
  );

  @include line-options(
    before,
    $transition-property: right,
    $value-of-transitioned-property: $max-value-of-transitioned-property / 2,
    $untransitioned-width: 50%
  );
  &:after {
    right: 0%;
    left: 0;
    bottom: 0;
  }

  &:before {
    z-index: 1;
    right: 0%;
    bottom: 0;
  }
}
