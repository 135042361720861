@mixin move-vertical {
  @include line-options(
    after,
    $transition-property: top,
    $value-of-transitioned-property:
      calc(#{$max-value-of-transitioned-property} - 100%)
  );
  @include line-options(
    before,
    $transition-property: bottom,
    $value-of-transitioned-property:
      calc(#{$max-value-of-transitioned-property} - 100%)
  );

  &:after {
    background-color: transparent;
    top: 50%;
    left: 0;
    right: 0;
    width: auto;
  }

  &:before {
    background-color: transparent;
    bottom: 50%;
    left: 0;
    right: 0;
    width: auto;
  }
}
