@mixin overline-goes-left {
  @include line-options(
    after,
    $transition-property: width,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );
  &:after {
    right: 0%;
    top: 0;
  }
}
