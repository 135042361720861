@import "base/imports";
.#{$name-of-the-class} {
  @include transition-options;
}

/* HALFLINE */
.#{$name-of-the-class}-halfline-bottom-goes-left {
  @include transition-options($before-bg-color: $green);
  @include halfline-bottom-goes-left;
}

.#{$name-of-the-class}-halfline-bottom-goes-right {
  @include transition-options($before-bg-color: $green);
  @include halfline-bottom-goes-right;
}

.#{$name-of-the-class}-halfline-top-goes-left {
  @include transition-options($before-bg-color: $green);
  @include halfline-top-goes-left;
}

.#{$name-of-the-class}-halfline-top-goes-right {
  @include transition-options($before-bg-color: $green);
  @include halfline-top-goes-right;
}

.#{$name-of-the-class}-halfline-bottom-left-goes-up {
  @include transition-options($before-bg-color: $green);
  @include halfline-bottom-left-goes-up;
}

.#{$name-of-the-class}-halfline-bottom-right-goes-up {
  @include transition-options($before-bg-color: $green);
  @include halfline-bottom-right-goes-up;
}

.#{$name-of-the-class}-halfline-top-left-goes-down {
  @include transition-options($before-bg-color: $green);
  @include halfline-top-left-goes-down;
}

.#{$name-of-the-class}-halfline-top-right-goes-down {
  @include transition-options($before-bg-color: $green);
  @include halfline-top-right-goes-down;
}

/* CORNERS CLOSE */
.#{$name-of-the-class}-cc-left-bottom {
  @include cc-left-bottom;
}

.#{$name-of-the-class}-cc-left-top {
  @include cc-left-top;
}

.#{$name-of-the-class}-cc-right-bottom {
  @include cc-right-bottom;
}

.#{$name-of-the-class}-cc-right-top {
  @include cc-right-top;
}

/* CORNERS EXPAND */
.#{$name-of-the-class}-ce-bottom-left {
  @include ce-bottom-left;
}

.#{$name-of-the-class}-ce-bottom-right {
  @include ce-bottom-right;
}

.#{$name-of-the-class}-ce-top-left {
  @include ce-top-left;
}

.#{$name-of-the-class}-ce-top-right {
  @include ce-top-right;
}

/* BOTH SIDES */
.#{$name-of-the-class}-both-go-down {
  @include both-go-down;
}

.#{$name-of-the-class}-both-go-left {
  @include both-go-left;
}

.#{$name-of-the-class}-both-go-right {
  @include both-go-right;
}

.#{$name-of-the-class}-both-go-up {
  @include both-go-up;
}

.#{$name-of-the-class}-both-opening-horizontal {
  @include both-opening-horizontal;
}

.#{$name-of-the-class}-overline-goes-left-underline-goes-right {
  @include overline-goes-left-underline-goes-right;
}

.#{$name-of-the-class}-overline-goes-right-underline-goes-left {
  @include overline-goes-right-underline-goes-left;
}

.#{$name-of-the-class}-left-goes-up-right-goes-down {
  @include left-goes-up-right-goes-down;
}

.#{$name-of-the-class}-left-goes-down-right-goes-up {
  @include left-goes-down-right-goes-up;
}

/* DOUBLE UNDERLINES */
.#{$name-of-the-class}-du-goes-left {
  @include du-goes-left;
}

.#{$name-of-the-class}-du-goes-right {
  @include du-goes-right;
}

.#{$name-of-the-class}-du-opening {
  @include du-opening;
}

.#{$name-of-the-class}-du-reversed-lr {
  @include du-reversed-lr;
}

.#{$name-of-the-class}-du-reversed-rl {
  @include du-reversed-rl;
}

/* DOUBLE LINETHROUGH */
.#{$name-of-the-class}-dl-goes-left {
  @include dl-goes-left;
}

.#{$name-of-the-class}-dl-goes-right {
  @include dl-goes-right;
}

.#{$name-of-the-class}-dl-reversed-lr {
  @include dl-reversed-lr;
}

.#{$name-of-the-class}-dl-reversed-rl {
  @include dl-reversed-rl;
}

.#{$name-of-the-class}-dl-opening {
  @include dl-opening;
}
/* OVERLINES  */
.#{$name-of-the-class}-overline-goes-left {
  @include overline-goes-left;
}

.#{$name-of-the-class}-overline-goes-right {
  @include overline-goes-right;
}

.#{$name-of-the-class}-overline-closing {
  @include overline-closing;
}

.#{$name-of-the-class}-overline-opening {
  @include overline-opening;
}

/* UNDERLINES */

.#{$name-of-the-class}-underline-goes-left {
  @include underline-goes-left;
}

.#{$name-of-the-class}-underline-closing {
  @include underline-closing;
}

.#{$name-of-the-class}-underline-opening {
  @include underline-opening;
}

.#{$name-of-the-class}-underline-goes-right {
  @include underline-goes-right;
}

/* one side */

.#{$name-of-the-class}-left-goes-down {
  @include left-goes-down;
}

.#{$name-of-the-class}-left-goes-up {
  @include left-goes-up;
}

.#{$name-of-the-class}-right-goes-up {
  @include right-goes-up;
}

.#{$name-of-the-class}-right-goes-down {
  @include right-goes-down;
}

/* LINETHROUGH */

.#{$name-of-the-class}-linethrough-opening {
  @include linethrough-opening;
}

.#{$name-of-the-class}-linethrough-closing {
  @include linethrough-closing;
}

.#{$name-of-the-class}-linethrough-goes-left {
  @include linethrough-goes-left;
}

.#{$name-of-the-class}-linethrough-goes-right {
  @include linethrough-goes-right;
}

/* MOVES */
.#{$name-of-the-class}-move-down {
  @include move-down;
}

.#{$name-of-the-class}-move-up {
  @include move-up;
}

.#{$name-of-the-class}-move-right {
  @include move-right;
}

.#{$name-of-the-class}-move-left {
  @include move-left;
}

.#{$name-of-the-class}-move-vertical {
  @include move-vertical;
}

.#{$name-of-the-class}-move-horizontal {
  @include move-horizontal;
}

/* FILLS */
.#{$name-of-the-class}-fill-goes-up {
  @include transition-options($hovered-color: $white);
  @include fill-goes-up;
}

.#{$name-of-the-class}-fill-goes-down {
  @include transition-options($hovered-color: $white);
  @include fill-goes-down;
}

.#{$name-of-the-class}-fill-goes-left {
  @include transition-options($hovered-color: $white);
  @include fill-goes-left;
}

.#{$name-of-the-class}-fill-goes-right {
  @include transition-options($hovered-color: $white);
  @include fill-goes-right;
}

.#{$name-of-the-class}-fill-opening-horizontal {
  @include transition-options($hovered-color: $white);
  @include fill-opening-horizontal;
}

.#{$name-of-the-class}-fill-closing-horizontal {
  @include transition-options($hovered-color: $white);
  @include fill-closing-horizontal;
}

.#{$name-of-the-class}-fill-closing-vertical {
  @include transition-options($hovered-color: $white);
  @include fill-closing-vertical;
}

/* FILL CORNERS */

.#{$name-of-the-class}-fc-top-left {
  @include transition-options($hovered-color: $white);
  @include fc-top-left;
}

.#{$name-of-the-class}-fc-top-right {
  @include transition-options($hovered-color: $white);
  @include fc-top-right;
}

.#{$name-of-the-class}-fc-bottom-left {
  @include transition-options($hovered-color: $white);
  @include fc-bottom-left;
}

.#{$name-of-the-class}-fc-bottom-right {
  @include transition-options($hovered-color: $white);
  @include fc-bottom-right;
}

.#{$name-of-the-class}-underline-left-delay {
  @include transition-options($transition-delay: 1s);
  @include underline-goes-left;
}
