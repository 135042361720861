@mixin move-left {
  @include line-options(
    after,
    $transition-property: left,
    $value-of-transitioned-property:
      calc(#{$max-value-of-transitioned-property} - 100%)
  );
  &:after {
    background-color: transparent;
    top: 0;
    bottom: 0;
    left: 50%;
    height: auto;
  }
}
