@mixin fill-goes-left {
  @include line-options(
    after,
    $transition-property: width,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );

  &:after {
    top: 0%;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}
