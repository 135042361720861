@mixin halfline-top-goes-right {
  @include line-options(
    after,
    $transition-property: none,
    $value-of-transitioned-property: $max-value-of-transitioned-property,
    $untransitioned-width: 100%,
    $untransitioned-height: $size-of-the-line
  );

  @include line-options(
    before,
    $transition-property: left,
    $value-of-transitioned-property: $max-value-of-transitioned-property / 2,
    $untransitioned-width: 50%
  );
  &:after {
    left: 0;
    top: 0;
  }

  &:before {
    z-index: 1;
    left: 0%;
    top: 0;
  }
}
