@mixin move-horizontal {
  @include line-options(
    after,
    $transition-property: right,
    $value-of-transitioned-property:
      calc(#{$max-value-of-transitioned-property} - 100%)
  );
  @include line-options(
    before,
    $transition-property: left,
    $value-of-transitioned-property:
      calc(#{$max-value-of-transitioned-property} - 100%)
  );
  &:after {
    background-color: transparent;
    top: 0;
    bottom: 0;
    right: 50%;
    height: auto;
  }

  &:before {
    background-color: transparent;
    top: 0;
    bottom: 0;
    left: 50%;
    height: auto;
  }
}
