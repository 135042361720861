@mixin setTransitions($transition-speed, $transition-easing, $hovered-color) {
  outline: 0;
  cursor: pointer;
  color: $hovered-color;
  transition: color $transition-speed $transition-easing;
}

@mixin setTransitionsForPseudoElements(
  $transition-speed,
  $transition-easing,
  $transition-delay
) {
  transition-duration: $transition-speed;
  transition-timing-function: $transition-easing;
  transition-delay: $transition-delay;
}

/* some effects transition more than one property at the same time */
@mixin checkTheLengthOfTransitionedProperties(
  $transition-property,
  $value-of-transitioned-property
) {
  @if length($transition-property) > 1 {
    @each $property in $transition-property {
      #{$property}: $value-of-transitioned-property;
    }
  } @else {
    #{$transition-property}: $value-of-transitioned-property;
  }
}

/* some effects transition height, some width, some both */
@mixin checkTheTransitionProperty(
  $transition-property,
  $untransitioned-height,
  $untransitioned-width
) {
  @if $transition-property == height {
    height: 0;
    width: $untransitioned-width;
  } @else if $transition-property == width {
    width: 0;
    height: $untransitioned-height;
  } @else {
    width: $untransitioned-width;
    height: $untransitioned-height;
  }
}

/* checks if the transition-property is set to none */
@mixin checkTheExistenceOfTransitionProperty($transition-property) {
  @if $transition-property == none {
    transition-property: none;
    @content;
  } @else {
    transition-property: $transition-property, background-color;
    @content;
  }
}
