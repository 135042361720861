@mixin fill-opening-horizontal {
  @include line-options(
    after,
    $transition-property: width,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );

  &:after {
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: auto;
  }
}
