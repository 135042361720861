/* https://coolors.co/ff5628-1e3541-2e5266-dee5e5-fcfafa */
$black: #1e3541;
$white: #fcfafa;
$red: #ff5628;
$blue: #2e5266;
$green: #4dabc8;

$event: "hover"; // event that will trigger the effect, you can choose between 'click' and 'hover'
$name-of-the-class: text-hover; // prefix all the effects with your class
$name-of-the-class-on-click: "active"; // use only when $event is set to "click", element with an effect will get the 'active' class on click that will trigger the effect

$hovered-text-color: $black; // color of the text on hover
$after-bg-color: $black;
$before-bg-color: $black;
$transition-speed: 0.7s; // transition-duration
$transition-easing: ease-in-out; // transition-timing-function
$transition-delay: 0s; // transition-delay
$size-of-the-line: 3px; // width or height of the pseudoelement
$space-above-the-text: 10px; // padding-top
$space-below-the-text: 10px; // padding-bottom
$space-on-the-left-side: 10px; // padding-left
$space-on-the-right-side: 10px; // padding-right
$display: inline; // display property, if you change it, I really hope you know what you are doing
