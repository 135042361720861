@mixin fill-closing-vertical {
  @include line-options(
    after,
    $transition-property: height,
    $value-of-transitioned-property: $max-value-of-transitioned-property / 2
  );

  @include line-options(
    before,
    $transition-property: height,
    $value-of-transitioned-property: $max-value-of-transitioned-property / 2
  );

  &:before {
    top: 0%;
    right: 0;
    left: 0;
    width: auto;
  }

  &:after {
    bottom: 0%;
    left: 0;
    right: 0;
    width: auto;
  }
}
