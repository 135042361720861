@mixin move-down {
  @include line-options(
    after,
    $transition-property: top,
    $value-of-transitioned-property: $max-value-of-transitioned-property
  );
  &:after {
    background-color: transparent;
    top: 50%;
    left: 0;
    right: 0;
    width: auto;
  }
}
